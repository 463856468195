body {
  font-family: Arial, Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.card-header img{
  max-width: 100%;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 9rem 2rem;
}
}


h3 {
  font-size: 1.375rem;
  line-height: 1.45;
}

h5 {
  font-size: .875rem;
  line-height: 1.72;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.checkbox-input{
  display: none;
}
.checkbox-parts{
  padding-left: 20px;
  position:relative;
  margin-right: 20px;
}
.checkbox-parts::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 4px;
}
.checkbox-input:checked + .checkbox-parts{
  color: #fff;
  font-weight: bold;
}
.checkbox-input:checked + .checkbox-parts::after{
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 5px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #d01137;
  border-right: 3px solid #d01137;
}

div.row .border-dark {
  border-color: rgb(168, 168, 168) !important;
  /*background-color: rgb(245, 245, 245);*/
}

.searchFrame{
  position: relative;
  display: inline-block;
}

.inputBox{
  padding-right: 20px;
  width:120px;
}

.searchFrame .clearButton{
  width: 13px;
  height: 13px;
  position: absolute;
  right: 2px;
  top: 1px;
  cursor: pointer;	
}

label {
  /*background-color: rgb(224, 224, 224);*/
  font-size: 0.9rem;
  cursor:pointer;
}

.text-center {
  text-align: center;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 1rem;
  font-size: 0.85rem;;
}

[dir=ltr] ol, [dir=ltr] ul {
  padding-left: 0px;
}

.container__body {
  position: relative;
  min-height: 70vh;
  z-index: 999;
}

span.br{
  display: block;
}

.card {
  height:100%;
}
.card-text {
  font-size: 9px;
}
.text-teal {
  color: #00B5AD;
}
.center {
  text-align: center;
}
.header {
  border: none;
  margin: calc(2rem - 0.14285em ) 0em 1rem;
  padding: 0em 0em;
  font-weight: bold;
  line-height: 1.2857em;
  text-transform: none;
}
.card-columns {
    column-count: 4;
    column-gap: 0.5rem;
}
.breadcrumb {
  background-color: #fff;
}
.mark, mark {
  padding: .2em;
  background-color: #ffe047;
}
.more{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.more:focus{
 white-space: normal;
  width: auto;
  overflow: visible;
  text-overflow: clip;

}

.badge-word {
  padding: 8px 20px 8px 8px;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 20px;
  position:relative;
  color: #212529;
  background-color: #dbdbdb;
  display: inline-block;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.batsu3{
  font-size: 1.5rem;
  cursor: pointer;
  cursor: hand;
  color:#616161;
  font-weight: 100;
}
.close3{
  position: absolute;
  right: 5%;
  top: 6%;
}

.batsu2{
  font-size: 1.8rem;
  cursor: pointer;
  cursor: hand;
}
.close2{
  position: absolute;
  right: 15%;
  top: 10%;
}

.batsu{
  font-size: 120%;
  cursor: pointer;
  cursor: hand;
}

.close{
  position: absolute;
  top: -5px;
  right: 0;
}
.card-body {
  padding: 0.8rem;
}

/*
.card {
  width:100%;
}
.col-3,.card-columns {
  display: flex;
}*/


@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

.pro-sidebar>.pro-sidebar-inner {
  background: #454c53;
}

.app {
  padding-top: 72px;
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}


.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none;
}

.pro-sidebar {
  left: -15px;
}

.spinner {
  width: 103%;
  left: -15px;
  transition-property: all; 
  transition-duration: 0.6s;
  transition-timing-function:ease-in-out
}

select.custom-select{
  color:#4f4f4f;
  font-weight: lighter;
  height: 40px;
  line-height: normal;
  padding: 9px 20px 11px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  font-size: .875rem;
}
/*
select.custom-select{
  color:#4f4f4f;
  font-weight: lighter;
  option{
    color:#4f4f4f;
    
    &:first-child{
      color:rgb(255, 0, 0) !important;
    }
  }
}
*/